import React from 'react';

import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';

import Unavailable from './components/Unavailable';
import TeacherChapter from './components/TeacherChapter';
import Writing from './components/Writing';
import WritingClosed from './components/WritingClosed';
import Voting from './components/Voting';
import Completed from './components/Completed';

const Chapter = ({ bookStatus, chapter, setSeenChapter, setSelectedChapter, resources = null }) => {
    const getContent = () => {
        if (!chapter) {
            return 'Nothing here'
        }
        
        switch(chapter.status) {
            case CHAPTER_STATUS.teacher_chapter:
                return <TeacherChapter bookStatus={bookStatus} chapter={chapter} setSelectedChapter={setSelectedChapter} />
            case CHAPTER_STATUS.new:
            case CHAPTER_STATUS.writing_ready:
                return <Unavailable chapter={chapter} />
            case CHAPTER_STATUS.writing:
                return <Writing chapter={chapter} resources={resources} />
            case CHAPTER_STATUS.writing_closed:
            case CHAPTER_STATUS.voting_ready:
                return <WritingClosed chapter={chapter} resources={resources} />
            case CHAPTER_STATUS.voting:
            case CHAPTER_STATUS.voting_closed:
                return <Voting chapter={chapter} />
            case CHAPTER_STATUS.completed:
                return <Completed chapter={chapter} setSeenChapter={setSeenChapter} />
            default:
                return 'Something is wrong'
        }
    }

    return getContent();
}

export default Chapter;
