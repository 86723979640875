import React from 'react';
import {
    HeaderOutput,
    ParagraphOutput,
    ImageOutput,
    ListOutput,
    QuoteOutput,
    WarningOutput,
    TableOutput,
    DelimiterOutput,
    CodeBoxOutput
} from 'editorjs-react-renderer';

const Output = ({ data, style }) => {
  if (!data || typeof data !== 'object') return '';
  if (!style || typeof style !== 'object') style = {};

  return data.blocks.map((block, i) => {
    switch (block.type.toLowerCase()) {
      case 'codebox': return <CodeBoxOutput key={`block-${i}`} data={ block.data } style={ style.codeBox || {}} />;
      case 'header': return <HeaderOutput key={`block-${i}`} data={ block.data } style={ style.header || {}} />;
      case 'paragraph': return <ParagraphOutput key={`block-${i}`} data={ block.data } style={ style.paragraph || {}} />;
      case 'image': return <ImageOutput key={`block-${i}`} data={ block.data } style={ style.image || {}} />;
      case 'table': return <TableOutput key={`block-${i}`} data={ block.data } style={ style.table || {}} />;
      case 'list': return <ListOutput key={`block-${i}`} data={ block.data } style={ style.list || {}} />;
      case 'quote': return <QuoteOutput key={`block-${i}`} data={ block.data } style={ style.quote || {}} />;
      case 'warning': return <WarningOutput key={`block-${i}`} data={ block.data } style={ style.warning || {}} />;
      case 'delimiter': return <DelimiterOutput key={`block-${i}`} style={ style.delimiter || {}} />;

      default: return '';
    }
  });
};

export {
  HeaderOutput, ParagraphOutput, ImageOutput, TableOutput, CodeBoxOutput, ListOutput, QuoteOutput, WarningOutput, DelimiterOutput,
  Output as default
};
