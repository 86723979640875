import React from 'react';
import styles from './VotingPanel.module.scss';
import { XCircle } from 'react-feather';
import InfoPanel from '../../../InfoPanel';
import stars from '../../../../../../images/stars.svg';

const VotingPanel = ({ children, title, whitePanel, closePanel, allowDismiss, icon  }) => {
    return(
        <div className={styles.votingPanel}>
            { allowDismiss && (
                <div className={styles.votingPanelHeader}>
                    <div onClick={closePanel} className={styles.votingPanelClose}><XCircle size="30" /></div>
                </div>
            )}
            <div className={styles.votingPanelContent}>
                <InfoPanel whitePanel icon={icon} title={title}>
                    <img src={stars} className={styles.votingPanelStars} alt="" />
                    {children}
                </InfoPanel>
            </div>
        </div>
    )
}

VotingPanel.defaultProps = {
    allowDismiss: false
}

export default VotingPanel;
