import React from 'react';
import { Router } from '@reach/router';

import Dashboard from '../../Dashboard';
import Book from '../../Book';
import TokenCheck from './TokenCheck';

const AppRouter = () => {
    return (
        <Router>
            <TokenCheck path='/'>
                <Dashboard path='/' default/>
                <Book path='/book/:bookId' />
            </TokenCheck>
        </Router>
    )
}

export default AppRouter;