import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import ls from 'local-storage';

import { Loader } from '@axeedge/go-pupil-components';

import { AuthContext } from '../../../services/user/AuthProvider';
import { GET_USER } from '../services/graphql';

const UserQueryHolder = () => {
    const { data, error, loading } = useQuery(GET_USER);
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    
    useEffect(() => {
        if (data && data.currentStudent && data.currentStudent.id && !currentUser && ls('go_pupil_user') !== 'undefined') {
            setCurrentUser(data.currentStudent);
        }
    }, [currentUser, data, setCurrentUser])

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    return null;
}

export default UserQueryHolder;

