import React from 'react';

import { ENTRY_RATINGS_LABELS } from '@axeedge/go-shared-utils';

import Star from '../Star';

import styles from './StarsHolder.module.scss';

const StarsHolder = ({
    displayOnly,
    enabled,
    labels = ENTRY_RATINGS_LABELS,
    rating,
    setRating,
    setTempRating
}) => {

    return (
        <div className={styles.stars}>
            {
                labels.map((_, i) => {
                    if (i === 0) {
                        return null;
                    }
                    return (
                        <Star
                            displayOnly={displayOnly}
                            key={`star-${i}`}
                            enabled={enabled}
                            rating={rating}
                            setRating={setRating}
                            setTempRating={setTempRating}
                            value={i}
                            alt={`Rating value: ${labels[i]}`}
                        />
                    )
                })
            }
        </div>
    );
}

export default StarsHolder;
