import React from 'react';

import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';

import Book from '../components/Book';

const Todo = ({ books }) => {
    if (books.length === 0) {
        return <p>Nothing to do at the moment!</p>
    }
    return books.map(book => {
        const isDisabled = (book.isSingleChapter && book.chapters[0].status < CHAPTER_STATUS.writing) || (!book.isSingleChapter && book.currentChapter === 0) || (!book.isSingleChapter && book.currentChapter === 1 && book.chapters[1].status < CHAPTER_STATUS.writing);
        if (isDisabled) {
            return null;
        }
        return <Book key={book.id} book={book} />
    })
}

export default Todo;