import React, { Fragment, useState, useEffect } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { Modal } from '@axeedge/go-pupil-components';
import EditorJsOutput from '../../../../../../components/EditorJsOutput';
import Tabs from '../../../../../../components/Tabs';
import Tab from '../../../../../../components/Tab';
import VocabModule from '../VocabModule';
import { highlightEntry } from '../../../../services/utils';

import { SEE_CHAPTER_WINNER, GET_STUDENT_ENTRY } from '../../../../services/graphql';
import styles from '../../Chapter.module.scss';


const Completed = ({ chapter, setSeenChapter }) => {

    const winnerEntry = chapter.studentEntries.find(entry => { return entry.winner });
    const [showWinner, setShowWinner] = useState(!chapter.currentStudentEntry.winner);
    const [errorModal, setErrorModal] = useState(null);

    const client = useApolloClient();

    const [seeChapterWinner] = useMutation(SEE_CHAPTER_WINNER, {
        onCompleted: ({ seeChapterWinner }) => {
            if (seeChapterWinner.errors && seeChapterWinner.errors.length !== 0) {
                setErrorModal(seeChapterWinner.errors[0])
                return;
            } else {
                client.writeQuery({
                    query: GET_STUDENT_ENTRY,
                    variables: {
                        id: seeChapterWinner.bookChapterStudentEntry.id
                    },
                    data: {
                        studentEntry: seeChapterWinner.bookChapterStudentEntry
                    }
                })
                setSeenChapter(chapter.orderid)
            }
        }
    });

    useEffect(() => {
        if ((winnerEntry && !chapter.currentStudentEntry.seenWinner) && !chapter.classBook.isSingleChapter) {
            seeChapterWinner({
                variables: {
                    bookChapterStudentEntryId: winnerEntry.id
                }
            });
        }
    }, [chapter.currentStudentEntry, winnerEntry, seeChapterWinner]);

    useEffect(() => {
        setShowWinner(!chapter.currentStudentEntry.winner)
    }, [chapter.orderid]);

    const renderEntry = entry => {
        return (
            <Fragment>
                {
                    entry ? (
                        <Fragment>
                            <h4 className='h2 heavy'>{entry.author.id === chapter.currentStudentEntry.author.id ? 'By you' : entry.author.name}</h4>
                            {
                                entry.text ? (
                                    <EditorJsOutput data={highlightEntry(JSON.parse(entry.winner ? entry.teacherEdit || entry.text : entry.text), chapter.vocabularyWords)} />
                                ) : (
                                    <p>No entry submitted for this chapter</p>
                                )
                            }
                        </Fragment>
                    ) : (
                        <p>No entry data</p>
                    )
                }
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div className={styles.chapter}>
                <div className={styles.chapterEntry}>
                    <div className={styles.chapterEntryContent}>
                        <h3 className='h2 heavy u-text-primary-dark'>{chapter.title}</h3>
                        {
                            !chapter.currentStudentEntry.winner && (
                                <Tabs className={styles.chapterEntryTabs}>
                                    <Tab active={showWinner} onClick={() => setShowWinner(true)}>Winner</Tab>
                                    <Tab active={!showWinner} onClick={() => setShowWinner(false)}>Your entry</Tab>
                                </Tabs>
                            )
                        }
                        {showWinner ? renderEntry(winnerEntry) : renderEntry(chapter.currentStudentEntry)}
                    </div>
                </div>

              

                <div className={styles.chapterSide}>
                    {chapter.vocabularyWords && chapter.vocabularyWords.length > 0 &&
                        <>
                            {showWinner && winnerEntry ? (
                                <VocabModule entry={JSON.parse(winnerEntry.text)} vocab={chapter.vocabularyWords} />
                            ) : <>{chapter.currentStudentEntry?.text && <VocabModule entry={JSON.parse(chapter.currentStudentEntry.text)} vocab={chapter.vocabularyWords} />}</>
                            }
                        </>
                    }
                </div>

                {
                    errorModal && (
                        <Modal closeModal={() => setErrorModal(null)}>
                            {errorModal}
                        </Modal>
                    )
                }
            </div>
        </Fragment>
    )
}

export default Completed;
