import React from 'react'
import { useQuery } from '@apollo/react-hooks';
import { GET_CLASS_BOOK } from './services/graphql';
import { Loader } from '@axeedge/go-pupil-components';
import BookHolder from './components/BookHolder';

const Book = ({ bookId }) => {

    const { data, error, loading } = useQuery(GET_CLASS_BOOK, {
        fetchPolicy: 'network-only',
        pollInterval: 60000,
        variables: {
            classBookId: bookId
        }
    });


    if (error) {
        return <p>{error.message}</p>
    }

    if (loading && !data) {
        return (
            <div className="content-panel">
                <Loader />
            </div>
        )
    }

    if (data.classBook && data.classBook.id) {
        return (

            <BookHolder bookData={data.classBook} />
        );
    }

    return null;
}

export default Book;
