// utils for book stuff

const countWords = str => {
    str = str.replace(/(^\s*)|(\s*$)/gi, ""); //exclude  start and end white-space
    str = str.replace(/[~`!@#$%^&*(){}[\];:"'<,.>?/\\|_+=-]/g, ""); // punctuation
    str = str.replace(/\s{2,}/g, " "); //2 or more space to 1
    str = str.replace(/\n /, "\n"); // exclude newline with a start spacing
    return str.trim().split(/\s+/).length;
}


export const updateEntry = (data, setEntry) => {
    let words = [];

    data.blocks.forEach((block) => {
        words.push(countWords(block.data.text));
    });

    setEntry({ text: data, wordCount: words.reduce((a, b) => a + b, 0) })
}


const escapeVocab = vocab => vocab.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const highlightVocab = (text, vocab) => {
    let newText = text;
    vocab.map(v => {
        let exp = RegExp('\\b(' + escapeVocab(v) + ')\\b', 'gi');
        newText = newText.replace(exp, `<span class=\"tagvocab\">$&</span>`)
    });
    return newText;
}

export const highlightEntry = (entryData, vocabWords) => {
    //remove empty string from vocabWords (old issue)
    if(vocabWords.filter(el => el).length > 0) {
        entryData.blocks.forEach(block => {
            if (block.data.text && block.data.text.length !== 0) {
                block.data.text = highlightVocab(block.data.text, vocabWords)
            }
        });
        return entryData
    }
    return entryData
}