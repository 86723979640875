import React, { useContext } from 'react';
import styles from './InfoPanel.module.scss';
import cx from 'classnames';
import {PupilAvatarViewer as Viewer} from '@axeedge/go-shared-components';
import { AuthContext } from '../../../../services/user/AuthProvider';
import boomer from '../../../../images/loader-boomer.png'
const InfoPanel = ({ children, whitePanel, title, icon: Icon }) => {

    const { currentUser: user } = useContext(AuthContext);

    
    return(
        <div className={styles.infoContainer}>
            <div className={styles.infoAvatar}>
                <img src={user.boomerAvatarUrl ? `${user.boomerAvatarUrl}?v=${user.updatedAt.replace(/\s/g, '' )}` : boomer} className={styles.infoAvatarImg} />
            </div>
            <div className={cx(styles.infoPanel,  {[styles.infoPanelWhite]: whitePanel})}>
                <h3 className={styles.infoTitle}><Icon /> {title}</h3>
                {children}
            </div>
        </div>
    )
}

export default InfoPanel;
