import React from 'react'
import cx from 'classnames';

import styles from './BoomerModal.module.scss';

const BoomerModal = ({ children, closeModal }) => {
    return (
        <div
            className={styles.modal}
            onClick={(event) => {
                if (event.target.classList.contains('blanker')) {
                    closeModal();
                }
            }}
        >
            <div className={cx('blanker', styles.modalBlanker)}>
                <div className={cx(styles.modalBoomerWrapper)}>
                    <div className={cx(styles.modalContent)}>
                        <div className={styles.modalContentInner}>
                            {children}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default BoomerModal