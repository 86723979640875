import React, { Fragment } from 'react';
import { Link } from '@reach/router';
import bwIcon from '../../images/menu/boomwritericon.png'
import NavLinks from './components/NavLinks';
import styles from './AppMenu.module.scss';

const AppMenu = ({ onLogout, user }) => {

    return(
        <Fragment>
            {
                user && (
                    <Fragment>
                        <div className={styles.menu}>
                            <div className={styles.menuContent}>
                                <div className={styles.menuBrand}><Link to="/"><img src={bwIcon} alt="BoomWriter" /></Link></div>
                                <NavLinks styles={styles} user={user} onLogout={onLogout}  />
                            </div>
                        </div>
                    </Fragment>
                )
            }
        </Fragment>
    )
}

export default AppMenu;
