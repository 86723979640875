import React from 'react';
import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';

import Book from '../components/Book';

const Writing = ({ books }) => {
    if (books.length === 0) {
        return <p>You've not done any Writing Tasks yet!</p>
    }
    return books.map(book => {
        const isDisabled = (book.isSingleChapter && book.chapters[0].status === CHAPTER_STATUS.new) || (!book.isSingleChapter && book.currentChapter === 0);
        if (isDisabled) {
            return null;
        }
        return <Book key={book.id} book={book} />
    })
}

export default Writing;