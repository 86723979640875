import React, { Fragment } from 'react';
import InfoPanel from '../../../InfoPanel';
import { CHAPTER_STATUS } from '@axeedge/go-shared-utils';

import { EyeOff } from 'react-feather';

const Unavailable = ({ chapter }) => {

    return (
        <Fragment>
            <h1 className="u-mb-3">{chapter.title}...</h1>
            <InfoPanel title="Nothing to see here" icon={EyeOff}>
                {
                    chapter.status === CHAPTER_STATUS.writing_ready ? (
                        <Fragment>
                            <p>Your teacher will start the chapter soon so check back later!</p>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <p>The previous chapter needs to be completed first.</p>
                            <p>You'll read the winning entry then you and your class will continue the story and StarCast to decide the chosen version.</p>
                        </Fragment>
                    )
                }
            </InfoPanel>
        </Fragment>
    )
}

export default Unavailable;
