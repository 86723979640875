import React from 'react';
import cx from 'classnames';

import starEmpty from '../../../../../../../../images/star-empty.svg';
import starFilled from '../../../../../../../../images/star-filled.svg';

import styles from './Star.module.scss';

const Star = ({ displayOnly, enabled, rating, setRating, setTempRating, value, alt }) => {
    return (
        <button
            className={cx(styles.holder, {[styles.displayOnly]: displayOnly}, {[styles.dulled]: !enabled})}
            onClick={() => setRating(value)}
            onMouseEnter={() => setTempRating(value)}
            onMouseLeave={() => setTempRating(null)}
        >
            <img alt={alt} src={value > rating ? starEmpty : starFilled} />
        </button>
    )
}

export default Star;
