import React, { Fragment, useState } from 'react';
import { Link } from '@reach/router';
import { XCircle } from 'react-feather';
import logo from '../../../../images/boomwriter_reversed.svg';
import bookImg from '../../../../images/book.png';
import ocBookImg from '../../../../images/oc-book.jpg';
import NavLinks from './components/NavLinks';

const BookMenu = ({ book, setSelectedChapter, selectedChapter }) => {

    const [mobileMenu, setMobileMenu] = useState(false);

    const toggleMenu = () => {
        mobileMenu ? setMobileMenu(false) : setMobileMenu(true);
    }

    const closeMenu = () => {
        setMobileMenu(false) ;
    }

    const chapter = book.chapters[selectedChapter];

    return(
        <Fragment>
            <div className="menu-header">
                <div onClick={toggleMenu} className="menu-header__brand">
                    <span className="menu-header__brand__toggle"></span>
                    <img src={logo} alt="BoomWriter" />
                </div>
                {
                    mobileMenu && (
                    <div className="menu-header__nav">
                        <div className="menu-header__nav__links">
                            <NavLinks book={book} closeMenu={closeMenu} setSelectedChapter={setSelectedChapter} selectedChapter={selectedChapter} />
                        </div>
                        <div className="menu-header__nav__content">
                            <div className="menu-header__nav__content__top">
                                <img className="menu-header__nav__content__top__img" src={book.isSingleChapter ? ocBookImg : bookImg} alt="" />
                                <span className="menu-header__nav__content__top__close"><XCircle onClick={toggleMenu} size="35" /></span>
                            </div>
                            <h1>{book.title || book.bookTemplate.name}</h1>
                            <h3>{selectedChapter === -1 ? '' : chapter.title}</h3>
                            <div className="menu-header__nav__content__base">
                                <p className="lead u-mb-0">
                                    {book.studentsClass.teachers[0] && book.studentsClass.teachers[0].displayName}
                                </p>
                                <p className="lead">{book.studentsClass.name}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="menu-sidebar-book">
                <div className="menu-sidebar__nav">
                    <NavLinks book={book} closeMenu={closeMenu} setSelectedChapter={setSelectedChapter} selectedChapter={selectedChapter} />
                </div>
            </div>
        </Fragment>
    )
}

export default BookMenu;
