import React, { Fragment, useState } from 'react'
import { useEffectOnce } from 'react-use';

import { BOOK_STATUS, CHAPTER_STATUS } from '@axeedge/go-shared-utils';

import BookMenu from './BookMenu';
import BookHeader from './BookHeader';
import Chapter from './Chapter';
import BookInfo from './BookInfo';

const BookHolder = ({ bookData }) => {
    const setSeenChapter = (sc) => {
        setSelectedChapter(sc);
    }

    const setSc = (bookData) => {
        if (bookData.classPack.isWritingActivity) {
            return 0;
        }
        if (bookData.chapters[bookData.currentChapter].orderid !== 0 && bookData.chapters[bookData.currentChapter - 1].status === CHAPTER_STATUS.completed && !bookData.chapters[bookData.currentChapter - 1].currentStudentEntry.seenWinner) {
            return bookData.currentChapter - 1
        }
        else if (bookData.status >= BOOK_STATUS.completed) {
            return -1
        }
        else {
            return bookData.currentChapter || 0
        }
    }

    const [selectedChapter, setSelectedChapter] = useState(setSc(bookData))

    useEffectOnce(() => {
        setSelectedChapter(setSc(bookData))
    });

    return (
        <Fragment>
            <div className='book-wrapper'>
                <BookMenu book={bookData} selectedChapter={selectedChapter} setSelectedChapter={setSelectedChapter} />
                <div className="content-panel">
                    {
                        selectedChapter !== -1 ? (
                            <Fragment>
                                <BookHeader chapter={bookData.chapters[selectedChapter]} teachers={bookData.studentsClass.teachers} classTitle={bookData.studentsClass.name} bookName={bookData.title || bookData.bookTemplate.name} />
                                <Chapter bookStatus={bookData.status} bookId={bookData.id} chapter={bookData.chapters[selectedChapter]} setSeenChapter={setSeenChapter} setSelectedChapter={setSelectedChapter} resources={bookData.bookTemplate.resources} />
                            </Fragment>
                        ) :
                            <BookInfo setSelectedChapter={setSelectedChapter} book={bookData} />
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default BookHolder;
